<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback /> {{ $t('Employee Hand Summary') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate="addPayload" />
      <v-row class="px-2">
        <v-col cols="12" md="6" lg="3" class="py-0">
          <v-select
            v-model="userSelected"
            :items="userList"
            dense
            outlined
            :label="$t('staff')"
            item-text="user_fullname"
            item-value="user_id"
            @change="addPayload(payload.start, payload.end)"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.serve_code`]="{ item }">
          <router-link
            v-if="item.serve_code"
            :to="{ name: 'showDetailService', params: { id: item.serve_id } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.serve_code }}
          </router-link>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <span
            :class="
              item.date == $t('total')
                ? 'font-weight-bold primary--text'
                : !item.user_fullname
                ? 'font-weight-bold primary--text'
                : ''
            "
          >
            <span v-if="item.date == $t('total')">
              {{ item.date }}
            </span>
            <span v-else>
              {{ item.date | sumdatetime }}
            </span>
          </span>
        </template>
        <template v-slot:[`item.cost`]="{ item }">
          <span
            :class="
              item.date == $t('total')
                ? 'font-weight-bold primary--text'
                : !item.user_fullname
                ? 'font-weight-bold primary--text'
                : ''
            "
          >
            {{ item.cost }}
          </span>
        </template>
      </v-data-table>
      <v-data-table
        :headers="columns2"
        :items="dataTableList2"
        class="mt-4"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import { reportUser, removeComma } from '../useExcel'
import Comeback from '../Comeback.vue'
import { sumdate, sumdatetimeen, sumdatetime } from '@/plugins/filters'
import userReport from '@/api/userReport'

export default {
  components: {
    Comeback,
    DateFilters,
  },
  filters: {
    sumdatetime,
  },
  setup() {
    const userList = ref([])
    const userSelected = ref('')
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const dataTableList = ref([])
    const options = ref({})
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('a_number'), value: 'serve_code', width: 110 },
      { text: i18n.t('staff'), value: 'user_fullname', width: 220 },
      { text: i18n.t('list'), value: 'list_name', width: 260 },
      { text: i18n.t('customer_patient'), value: 'customer_name', width: 220 },
      { text: i18n.t('customer_code'), value: 'customer_id', width: 110 },
      { text: i18n.t('tel'), value: 'customer_tel', width: 120 },
      { text: i18n.t('group_customers'), value: 'customer_group_name', width: 160 },
      { text: i18n.t('date'), value: 'date', width: 170 },
      {
        text: i18n.t('net_amount'),
        value: 'cost',
        width: 180,
        align: 'end',
      },
    ])
    const exportLoading = ref(false)
    const dataTableList2 = ref([])
    const dataDetail = ref([])

    userReport.userReportList().then(res => {
      userList.value = res
      userList.value.unshift({
        user_id: '',
        user_fullname: i18n.t('all_staff'),
      })
    })

    const columns2 = ref([
      { text: i18n.t('staff_team'), value: 'user_fullname', width: 300 },

      {
        text: i18n.t('commissions'),
        value: 'servinguser_cost',
        width: 300,
        align: 'end',
      },
      {
        text: i18n.t('total'),
        value: 'total',
        width: 300,
        align: 'end',
      },
    ])

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        user_id: userSelected.value,
        lang: i18n.locale,
      }
      saerchReport(payload.value)
    }

    const saerchReport = async payload => {
      loading.value = true
      const { data, datauser, userCostList } = await reportUser(payload)
      dataTableList.value = data
      dataTableList2.value = datauser
      dataDetail.value = userCostList
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const fileName = `${i18n.t('EmployeeHandSummary')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(
        end,
      )} .xlsx`
      let dataExport = dataTableList.value.map((item, i) => {
        delete item.serve_id

        return { number: i + 1, ...item }
      })
      let dataExport2 = dataDetail.value.map((item, i) => {
        item.serve_date = sumdatetime(item.serve_date)
        return { number: i + 1, ...item }
      })
      if (dataTableList2.value) {
        dataTableList2.value.forEach(item => {
          dataExport.push({ date: item.user_fullname, cost: item.total })
        })
      }
      dataExport = await removeComma(JSON.parse(JSON.stringify(dataExport)))

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [`${i18n.t('EmployeeHandSummary')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)} `],
        [
          '#',
          `${i18n.t('a_number')}`,
          `${i18n.t('staff')}`,
          `${i18n.t('list')}`,
          `${i18n.t('customer_patient')}`,
          `${i18n.t('customer_code')}`,
          `${i18n.t('tel')}`,
          `${i18n.t('group_customers')}`,
          `${i18n.t('date')}`,
          `${i18n.t('net_amount')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'serve_code',
          'user_fullname',
          'list_name',
          'customer_name',
          'customer_id',
          'customer_tel',
          'customer_group_name',
          'date',
          'cost',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 9 } }]
      const wscols = [
        { wch: 5 },
        { wch: 10 },
        { wch: 25 },
        { wch: 40 },
        { wch: 25 },
        { wch: 15 },
        { wch: 18 },
        { wch: 20 },
        { wch: 25 },
        { wch: 15 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('EmployeeHandSummary')}`)

      // ============================================================================================================================================================================
      const Heading2 = [
        [`${i18n.t('fotmarListSetCourse')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)}`],
        [
          '#',
          i18n.t('a_number'),
          i18n.t('date'),
          i18n.t('staff'),
          i18n.t('list'),
          i18n.t('customer_code'),
          i18n.t('customer'),
          i18n.t('tel'),
          i18n.t('drug_code'),
          i18n.t('drug_name'),
          i18n.t('amount'),
          i18n.t('unit'),
          i18n.t('fee_cost'),
        ],
      ]
      const ws2 = XLSX.utils.aoa_to_sheet(Heading2)
      XLSX.utils.sheet_add_json(ws2, await removeComma(JSON.parse(JSON.stringify(dataExport2))), {
        header: [
          'number',
          'serve_code',
          'serve_date',
          'user_fullname',
          'course_name',
          'customer_id',
          'customer_fullname',
          'customer_tel',
          'servingdetail_drug_id',
          'servingdetail_drug_name',
          'servingdetail_drug_amount',
          'servingdetail_drug_unit',
          'cost_user',
        ],
        skipHeader: true,
        origin: -1,
      })

      const merge2 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 18 } }]
      const wscols2 = [
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ]
      ws2['!merges'] = merge2
      ws2['!cols'] = wscols2

      XLSX.utils.book_append_sheet(wb, ws2, `${i18n.t('fotmarListSetCourse')}`)

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      userList,
      userSelected,
      loading,
      dataTableList,
      options,
      payload,
      columns,
      exportLoading,
      dataTableList2,
      columns2,
      exportExcel,
      addPayload,
      saerchReport,
      mdiFileExcelOutline,
    }
  },
}
</script>
